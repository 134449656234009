/**
 * [criss css]
 * @plug-in.es
 */

body {
    background: white;
    letter-spacing: 0.5px;
    padding-top: 70px;
}
header {
    background: $azul;
    color: white;
    height: 72px;
    .logo-header {
        width: 187px;
        height: auto;
    }
}
.padding-header {
    padding-top: 70px;
}
footer {
    background-color: $azul!important;
    .container{
        border-top: 1px solid #fff;
    }
    .logo-footer {
        max-width: 178px;
        height: auto;
    }
    p {
        // font: normal 14px 'Chronicle', sans-serif;
        font-size: 14px;
        font-weight: normal;
        color: #fff;
        line-height: 1.2;
        a {
            color: #fff!important;
            &:hover, &.focus, &:focus, &.active, &:active {
                color: white!important;
                text-decoration: none!important;
            }
        }
        .separador{
            padding:0 5px;
        }
    }
    @include media-breakpoint-down(sm) {
        padding-bottom:100px;
    }
}
h1, .h1 {
    font: 500 48px 'Chronicle', sans-serif;
    color: $beige;
    line-height: 1.2;
    letter-spacing: 0.5px;
}
h2, .h2 {
    font: normal 42px 'Chronicle', sans-serif;
    color: $azul;
    line-height: 1.12;
    letter-spacing: normal;
    margin-bottom: 20px;
    @include media-breakpoint-down(sm) {
        font-size:30px;
    }
    &.title-s{
        font-size: 36px;
    }
}
h3, .h3 {
    font: 500 32px 'Chronicle', sans-serif;
    color: $azul;
    letter-spacing: 0.5px;
    line-height: 0.75;
}
h4, .h4 {
    font: normal 20px 'Neutra Text Alt', sans-serif;
    color: $gris-oscuro;
    line-height: 1.2;
}
h5, .h5 {
    font: 500 25px 'Neutra Text Alt', sans-serif;
    color: $azul;
    line-height: 0.96;
}
h6, .h6 {
    font: normal 25px 'Chronicle', sans-serif;
    font-style: italic;
}
p {
    font: 300 25px 'Neutra Text', sans-serif;
    color: white;
    line-height: 1.2;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
}
.line-section {
    background-image: url("/dist/images/line.svg");
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 5px;
}
.line-section-azul {
    background-image: url("/dist/images/line-azul.svg");
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 5px;
}
.navbar-toggler {
    width: 47px;
    height: 34px;
    border: none;
    .line {
        width: 100%;
        float: left;
        height: 3px;
        background-color: $beige;
        margin-bottom: 5px;
    }
}
#navbarMenu {
    z-index: 1;
}
.navbar-collapse {
    background: rgba(255, 255, 255, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0 auto;
    .navbar-nav {
        li {
            a {
                font: 500 28px 'Chronicle', sans-serif;
                color: $azul;
                letter-spacing: 0.5px;
                line-height: 0.75;
                text-decoration: none;
                text-transform: capitalize;
                @include media-breakpoint-down(sm) {
                    font-size:20px;
                    line-height:1.2em;
                }
            }
        }
    }
    .navbar-toggler {
        right: 25px;
        margin-top: 15px;
        position: fixed;
        cursor: pointer;
        .line-oblique {
            width: 100%;
            float: left;
            height: 2px;
            background-color: $azul;
            margin-bottom: 5px;
            transform: rotate(45deg);
            &.last {
                transform: rotate(-45deg);
                margin-top: -6px;
            }
        }
    }
}
.btn-primary {
    font: normal 18px 'Chronicle', sans-serif;
    background: transparent;
    border: 1px solid $azul;
    color: $azul;
    cursor: pointer;
    &:hover {
        cursor: pointer;
    }
    &.focus, &:focus, &.active, &:active {
        color: $azul;
    }
}
.btn-secundary {
    font: normal 18px 'Chronicle', sans-serif;
    background: transparent;
    border: 1px solid white;
    color: white;
    &:hover {
        background-color: white;
        border-color: $azul;
        color: $azul;
        cursor: pointer;
    }
    &.focus, &:focus, &.active, &:active {
        color: white;
    }
}
.btn-link{   
    font: bold 16px 'Neutra Text Alt', sans-serif;
    color: white;
    letter-spacing: 1.6px;
    &:hover {
        color: $blanco;
        text-decoration:underline;
    }
    &.focus, &:focus, &.active, &:active {
        color: white;
    }
}
.btn-contactanos {
    font: bold 18px 'Neutra Text', sans-serif;
    line-height: 1.6em;
    background: transparent;
    border: 1px solid $azul;
    color: $azul;
    cursor: pointer;
    text-transform: uppercase;
    &:hover {
        cursor: pointer;
    }
    &.focus, &:focus, &.active, &:active {
        color: $azul;
    }
}
.btn-submit {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background-color: $beige;
    height: 45px;
    font: normal 14px 'Neutra Text Alt', sans-serif;
    color: $azul;
    line-height: 1.5;
    text-transform: uppercase;
    letter-spacing: 2.6px;
    margin-top: 22px;
    width: 215px;
    &:hover {
        background-color: white;
        color: $azul;
        cursor: pointer;
    }
    &.focus, &:focus, &.active, &:active {
        color: $azul;
    }
}
.overlay-bg {
    background: rgba(0, 0, 0, 0.26);
    width: 100%;
    height: 100%;
    position: absolute;
    // top: -80px;
}
.bt-azul{
    border-top: 20px solid $azul;
}
#presentacion {
    background-image: url("/dist/images/hero-bg.jpg");
    background-color: transparent;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top:0px;
    position: relative;
    .container {
        height: 617px;
    }
    p {
        font: lighter 25px 'Neutra Text', sans-serif;
        font-style: normal;
    }
}
#que_es_compliance {
    margin-top: 100px;
    @include media-breakpoint-down(sm) {
        margin-top:10px;
    }
    p {
        font: 300 16px 'Neutra Text', sans-serif;
        color: $azul;
        line-height: 1.333;
        letter-spacing: 1.43px;
        &.etiqueta{
            margin-top: 20px;
            color:$beige;
            font: 600 12px 'Neutra Text', sans-serif;
            letter-spacing: 2.6px;
        }
    }
}
#servicios {
    margin-top: 100px;
    @include media-breakpoint-down(sm) {
        margin-top:10px;
    }
    h3{
        @include media-breakpoint-down(sm) {
            font-size:28px;
        }
    }
    p,ul {
        font: 300 16px 'Neutra Text', sans-serif;
        color: $azul;
        line-height: 1.333;
        letter-spacing: 1.43px;
        &.etiqueta{
            margin-top: 20px;
            color:$beige;
            font: 600 12px 'Neutra Text', sans-serif;
            letter-spacing: 2.6px;
        }
    }
    ul{
        padding-left: 10px;
        li{
            list-style: none;
            &:before{
                content: "\2022";
                color: $beige;
                font-weight: bold;
                margin-left: -15px;
                padding: 5px;
            }
        }
    }
    .link{
        color:$azul;
        margin-top: 10px;
        display: block;
        font-family: 'Neutra Text';
        font-size:15px;
        font-weight: 700;
        img{
            width: 15px;
            margin-right: 4px;
            margin-top:-3px;
        }
    }
}
#experiencia {
    background-image: url("/dist/images/contacta.jpg");
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding:150px 0 120px; 
    margin-top:100px;
    @include media-breakpoint-down(sm) {
        padding-top: 30px;
        padding-bottom: 30px;
        margin-top:20px;
    }
    h2{
        font: 400 52px 'Chronicle', sans-serif;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        display: inline-block;
        padding: 0 20px;
        background-color: $azul;
        margin-top: 30px;
        span{
            font-size: 30px;
        }
        div{
            transform: translateY(-15px);
            line-height: 30px;
            @include media-breakpoint-down(sm) {
                transform: translateY(-15px);
                line-height: 1em;
                font-size:35px;
            }
        }
    }
    p{
        font: 400 25px 'Chronicle', sans-serif;
        font-style: italic;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        margin-bottom: 40px;
        padding: 0 8vw;
    }
    a{
        border:1px solid #fff;
        font: normal 14px 'Neutra Text Alt', sans-serif;
        line-height: 2.29;
        letter-spacing: 2.6px;
        text-align: center;
        color: #fff;
        padding: 13px 20px 10px;
        &:hover{
            text-decoration: underline;
        }
    }
}
#equipo_compliance {
    @include media-breakpoint-down(sm) {
        margin-top:10px;
    }
    h5 {
        font: 500 25px 'Chronicle', sans-serif;
        color: $azul;
        line-height: 0.96;
        text-align: left;
    }
    h6 {
        font: normal 25px 'Chronicle', sans-serif;
        color: $azul;
        font-style: italic;
        line-height: 0.96;
        text-align: left;
    }
    p {
        font: 300 18px 'Neutra Text', sans-serif;
        color: $gris-oscuro;
        line-height: 1.7;
        text-align: left;
        &.card-subtitle {
            font: 500 22px 'Neutra Text Alt', sans-serif;
            line-height: 1.091;
        }
    }
}
#equipo_swipper {
    background-color: $azul;
    .swiper-container {
        width: 100%;
        height: 100%;
    }
    .swiper-slide {
        img {
            -webkit-border-radius: 60%;
            -moz-border-radius: 60%;
            border-radius: 60%;
            height: 124px;
            width: 124px;
        }
        h5 {
            font: 500 20px 'Chronicle', sans-serif;
            color: $beige;
            line-height: 1.2;
        }
        h6 {
            font: normal 20px 'Chronicle', sans-serif;
            color: $beige;
            font-style: italic;
            line-height: 1.2;
        }
        p {
            font: lighter 16px 'Neutra Text', sans-serif;
            color: white;
            line-height: 1.2;
        }
        hr {
            height: 1px;
            width: 100%;
            background-color: #616161;
        }
    }
    .swiper-pagination-bullet {
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        font-size: 12px;
        color: white;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";
        filter: alpha(opacity=1);
        -moz-opacity: 1;
        -khtml-opacity: 1;
        opacity: 1;
    }
    .swiper-pagination-bullet-active {
        color: $beige;
    }
    .arrow-left {
        cursor: pointer;
        background-image: url("/dist/images/ico-arrow.svg");
        background-color: transparent;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        height: 28px;
        top: 50%;
        left: -40px;
        width: 17px;
        transform: translateY(-50%);
        margin-top: -17px;
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: "FlipH";
    }
    .arrow-right {
        cursor: pointer;
        background-image: url("/dist/images/ico-arrow.svg");
        background-color: transparent;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        height: 28px;
        top: 50%;
        right: -40px;
        width: 17px;
        transform: translateY(-50%);
    }
}
#canalcumplimiento_eu {
    margin-top: 100px;
    padding:100px 0;
    background-color: $beige;
    @include media-breakpoint-down(sm) {
        margin-top:20px;
        padding-top:20px;
        padding-bottom:20px;
    }
    h2{
        font: 400 12px 'Neutra Text', sans-serif;
        line-height: 2.29;
        letter-spacing: 2.6px;
        color: #fff;
        margin-bottom: 0;
    }
    h3{
        font: normal 42px 'Chronicle', sans-serif;
        font-style: normal;
        line-height: 1.12;
        letter-spacing: normal;
        font-weight: normal;
        color:$azul;
        @include media-breakpoint-down(sm) {
            font-size:28px;
        }
    }
    p{
        font: normal 22px 'Chronicle', sans-serif;
        font-style: italic;
        line-height: 1.07;
        letter-spacing: normal;
        font-weight: normal;
        color:$azul;

    }
    .contenido{
        margin-top: 50px;
        .item{
            &:before{
                content:" ";
                width: 210px;
                height: 210px;
                border-radius: 50%;
                background-color: rgba(255,255,255,0.3);
                display: block;
                position: absolute;
                left: 50%;
                margin-left: -150px;
                margin-top: -20px;
            }
            img{
                margin-left: 25px;
                position: relative;
                z-index: 2;
                max-height: 170px;
            }
            p{
                font: 400 14px 'Neutra Text', sans-serif;
                font-style: normal;
                line-height: 1.2;
                letter-spacing: 1.43px;
                color: $azul;
                position: relative;
                z-index: 2;
                margin-top: 35px;
            }
        }
    }
}
#preguntas_frecuentes {
    margin-top: 100px;
    @include media-breakpoint-down(sm) {
        margin-top:10px;
    }
    p {
        font: 300 22px 'Chronicle', sans-serif;
        color: $azul;
        line-height: 1.333;
        letter-spacing: 0px;
        font-style: italic;
        &.etiqueta{
            margin-top: 20px;
            margin-bottom: 20px;
            color:$beige;
            font: 600 12px 'Neutra Text', sans-serif;
            letter-spacing: 2.6px;
        }
    }
    .accordion {
        border-bottom:2px solid $beige;
        &.first {
            border-top:2px solid $beige;
        }
        .accordion-header {
            background: transparent;
            cursor: pointer;
            .btn-accordion {
                border-bottom: 1px dashed rgba(255, 255, 255, 0.4);
                -webkit-border-radius: inherit !important;
                -moz-border-radius: inherit !important;
                border-radius: inherit !important;
                font: normal 14px 'Neutra Text', sans-serif;
                font-weight: bold;
                line-height: 1.6em;
                text-align: left;
                width: 100%;
                text-align: left;
                background-color: white;
                color: $azul;
                white-space: normal;
                padding:11px 0 8px;
                &:after {
                    background-image: url("/dist/images/ico-minus.svg");
                    content: "";
                    width: 18px;
                    height: 18px;
                    display: inline-block;
                    margin-right: 15px;
                    vertical-align: text-top;
                    background-color: transparent;
                    background-position: center center;
                    background-repeat: no-repeat;
                    position: absolute;
                    right: 0;
                }
                &.collapsed {
                    // background-color: transparent;
                    // color: $beige;
                    &:after {
                        background-image: url("/dist/images/ico-plus.svg");
                    }
                }
            }
        }
        .accordion-body {
            border-top: 1px solid $azul;
            p {
                font: normal 16px 'Neutra Text Alt', sans-serif;
                color: $azul;
                line-height: 1.5;
                text-align: left;
                font-style: italic;
                margin-left: 15px;
            }
            ul {
                li {
                    font: normal 16px 'Neutra Text Alt', sans-serif;
                    color: $azul;
                    line-height: 1.5;
                    text-align: left;
                    font-style: italic;
                }
            }
        }
    }
}
#que_dicen_de_compliance {
    @include media-breakpoint-down(sm) {
        margin-top:10px;
    }
    h6 {
        font: 500 26px 'Chronicle', sans-serif;
        color: $azul;
        line-height: 0.923;
        text-align: center;
    }
    .swiper-container-testimonios {
        width: 100%;
        height: 100%;
    }
    .swiper-slide {
        border-width: 2px;
        border-color: rgb(233, 233, 233);
        border-style: solid;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        padding: 26px;
        width: 530px;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
        filter: alpha(opacity=40);
        -moz-opacity: 0.4;
        -khtml-opacity: 0.4;
        opacity: 0.4;
        &.swiper-slide-active {
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";
            filter: alpha(opacity=1);
            -moz-opacity: 1;
            -khtml-opacity: 1;
            opacity: 1;
        }
        .img-testimonio {
            -webkit-border-radius: 60%;
            -moz-border-radius: 60%;
            border-radius: 60%;
            height: 94px;
            width: 94px;
            border: 1px solid $beige;
        }
        .testimonio-text {
            .ico {
                max-width: 64px;
            }
            p {
                font: lighter 18px 'Neutra Text', sans-serif;
                color: $gris-oscuro;
                line-height: 1.333;
                text-align: left;
                &.nombre {
                    font: 500 16px 'Chronicle', sans-serif;
                    line-height: 1.5;
                }
            }
        }
    }
    .swiper-pagination2 {
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        font-size: 12px;
        color: white;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";
        filter: alpha(opacity=1);
        -moz-opacity: 1;
        -khtml-opacity: 1;
        opacity: 1;
    }
    .swiper-pagination2-active {
        color: $beige;
    }
}
#confia_en_nosotros {
    background-color: $gris-medio;
    @include media-breakpoint-down(sm) {
        margin-top:10px;
    }
}
#contacto {
    padding: 100px 0;
    background-color: $azul;
    @include media-breakpoint-down(sm) {
        padding-top:20px;
    }
    h2{
        color: #fff;
        line-height: 2.29;
        letter-spacing: 2.6px;
        font: 400 12px 'Neutra Text', sans-serif;
        letter-spacing: 2.6px;
        margin-bottom: 0;
    }
    h3{
        font: normal 42px 'Chronicle', sans-serif;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.12;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        margin-bottom: 15px;
        @include media-breakpoint-down(sm) {
            font-size:28px;
        }
    }
    p {
        font: normal 22px 'Chronicle', sans-serif;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
    }
    form {
        .pretty{
            &.p-default input:checked ~ .state label::after {
                top: 6px;
            }
            .state label.form-check-label::before {
                top: 6px;
            }
            input{
                z-index: 1;
                width: 1px;
                height: 1px;
                border:none;
            }
            .state{
                @include media-breakpoint-down(sm) {
                    margin-left: -15px;
                    margin-top: 3px;
                }
            }
        }
        label {
            font: 600 12px 'Neutra Text Alt', sans-serif;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.31;
            letter-spacing: 1.6px;
            color: #fff;
            margin-bottom: 0;
            &.form-check-label {
                font: 400 12px 'Neutra Text Alt', sans-serif;
                color: #fff;
                line-height: 1.714;
                padding-left: 5px;
                a{
                    color: #fff;
                }
            }
        }
        .form-group{
            margin-bottom: 0;
        }
        input {
            z-index: 2;
            border:none;
            margin-bottom: 5px;
            min-height: 30px;
            padding: 0 5px;
            color: $azul;
            font-size: 14px;
            box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.5);            
        }
        textarea{
            resize: none;
            height: 190px;
            padding: 0 5px;
            color: $azul;
            font-size: 14px;
            box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.5);            
        }
        a {
            color: $azul;
            &:hover {
                text-decoration: none;
            }
        }
    }
    table {
        line-height: 1.2em;
        font: 400 12px 'Neutra Text Alt', sans-serif;
        tr {
            td {
                border: solid 1px $gris-medio;
                color: $gris-medio;
                padding: 4px;
                a {
                    color: $gris-medio;
                }
            }
        }
    }
    .con-accordion-legal{
        margin-top: -35px;
        @include media-breakpoint-down(sm) {
            margin-top:15px;
        }
        button{
            border: 1px solid $blanco;
            background-color:transparent;
            border-radius: 0px;
            color: $blanco;
            padding-left: 10px;
            text-transform: uppercase;
            font: normal 12px Neutra Text Alt,sans-serif;
            color:$blanco;
            display:block;
            &:after{
                width:13px;
                height:13px;
                content: url("/dist/images/ico-menos.svg");
                position:absolute;
                right:21px;
            }
            &.collapsed{
                &:after{
                    content: url("/dist/images/ico-mas.svg");
                }
            }
        }
        .accordion-body{
            border:1px solid $blanco;
            height:245px;
            overflow-y: scroll;
            margin-top:-1px;
            h4{
                font: normal 12px Neutra Text,sans-serif;
                color:$blanco;
                text-transform: uppercase;
            }
            .bloque-1, .bloque-2, .bloque-3, .bloque-4, .bloque-5, .bloque-6{
                border-bottom:1px solid $blanco;
                padding-top:15px;
                padding-left:10px;
                padding-right:10px;
                padding-bottom:10px;
                width: 95%;
                border-right: 1px solid $blanco;
                p{
                   font: normal 14px Neutra Text Alt,sans-serif;
                   color:#7f7f7f;
                   font-size:13px;
                   line-height:1.4em; 
                   margin-bottom:0px;
                }
            }
            .bloque-6{
                border-bottom:none;
            }
        }
    }
}
#legal {
    p {
        font: 300 18px 'Neutra Text', sans-serif;
        color: $azul;
        line-height: 1.333;
        letter-spacing: 0.5px;
        text-align: justify;
        &.subtitle {
            font: 300 30px 'Chronicle', sans-serif;
            font-style: italic;
            color: $gris-oscuro;
            line-height: 1.2;
            letter-spacing: 0.5px;
            width: 50%;
        }
    }
}
#para_quien {
    padding:100px 0;
    @include media-breakpoint-down(sm) {
        padding-top:20px;
        padding-bottom:20px;
    }
    p {
        font: 300 22px 'Chronicle', sans-serif;
        color: $azul;
        line-height: 1.333;
        letter-spacing: 0px;
        font-style: italic;
        &.etiqueta{
            margin-top: 0;
            margin-bottom: 20px;
            color:$beige;
            font: 600 12px 'Neutra Text', sans-serif;
            letter-spacing: 2.6px;
        }
    }
    ul{
        padding-left: 0;
        li{
            list-style: none;
            font: bold 12px 'Neutra Text Alt', sans-serif;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.85;
            letter-spacing: 1.6px;
            text-align: center;
            color: $azul;
            border-top: 2px solid #d7d7d7;
            padding:10px 0;
            &:last-child{
                border-bottom: 2px solid #d7d7d7;
            }
            &:nth-child(4n){
            
                @include media-breakpoint-down(sm) {
                    border-bottom:none;
                }
            }
        }
    }
}


/*--------------------------------------------------------------
# Media Query
--------------------------------------------------------------*/

/*--------------------------------------------------------------
## Tablet +
--------------------------------------------------------------*/

@media only screen and (max-width: 1100px) {}
@media only screen and (max-width: 960px) {
    html {
        font-size: 85%;
    }
    #que_es_compliance {
        .subtitle {
            width: 100% !important;
        }
    }
    .media {
        display: block !important;
    }
}
/*--------------------------------------------------------------
## Tablet
--------------------------------------------------------------*/

@media only screen and (max-width: 768px) {
    html {
        font-size: 80%;
    }
    #que_dicen_de_compliance {
        .swiper-slide {
            width: 100%;
        }
    }
}
/*--------------------------------------------------------------
## Mobile
--------------------------------------------------------------*/

@media only screen and (max-width: 480px) {
    html {
        font-size: 75%;
    }
    .navbar {
        position: absolute;
        right: 0;
        top: 10px;
    }

}