// Grid settings
$enable-flex : true;
$main-sm-columns : 12;
$sidebar-sm-columns : 4;
// Colors
$azul:#19223d;
$azul-active:#467e96;
$azul-noactive:#66bbe0;
$coral:#e9805b;
$beige:#e7d67e;
$neoscuro:#393939;
$blanco-gris:#f4f4f4;
$placeholder:#6f6f6f;
$inputborder:#e1e1e1;
$gris:#FAFAFA;
$gris-text:#565656;
$gris-claro:#f7f7f7;
$gris-medio:#cfcfcf;
$gris-oscuro:#7d7d7d;
$blanco:#fff;