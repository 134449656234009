@font-face {
    font-family: 'Neutra Text Alt';
    src: url('/dist/fonts/NeutraText-BookAlt.eot');
    src: url('/dist/fonts/NeutraText-BookAlt.eot?#iefix') format('embedded-opentype'), url('/dist/fonts/NeutraText-BookAlt.woff2') format('woff2'), url('/dist/fonts/NeutraText-BookAlt.woff') format('woff'), url('/dist/fonts/NeutraText-BookAlt.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Neutra Text';
    src: url('/dist/fonts/NeutraText-Bold.eot');
    src: url('/dist/fonts/NeutraText-Bold.eot?#iefix') format('embedded-opentype'), url('/dist/fonts/NeutraText-Bold.woff2') format('woff2'), url('/dist/fonts/NeutraText-Bold.woff') format('woff'), url('/dist/fonts/NeutraText-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Neutra Text TF';
    src: url('/dist/fonts/NeutraTextTF-Book.eot');
    src: url('/dist/fonts/NeutraTextTF-Book.eot?#iefix') format('embedded-opentype'), url('/dist/fonts/NeutraTextTF-Book.woff2') format('woff2'), url('/dist/fonts/NeutraTextTF-Book.woff') format('woff'), url('/dist/fonts/NeutraTextTF-Book.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Neutra Text';
    src: url('/dist/fonts/NeutraText-Light.eot');
    src: url('/dist/fonts/NeutraText-Light.eot?#iefix') format('embedded-opentype'), url('/dist/fonts/NeutraText-Light.woff2') format('woff2'), url('/dist/fonts/NeutraText-Light.woff') format('woff'), url('/dist/fonts/NeutraText-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Neutra Text';
    src: url('/dist/fonts/NeutraText-BoldItalic.eot');
    src: url('/dist/fonts/NeutraText-BoldItalic.eot?#iefix') format('embedded-opentype'), url('/dist/fonts/NeutraText-BoldItalic.woff2') format('woff2'), url('/dist/fonts/NeutraText-BoldItalic.woff') format('woff'), url('/dist/fonts/NeutraText-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Chronicle';
    src: url('/dist/fonts/ChronicleDisplaySemibold.otf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Chronicle';
    src: url('/dist/fonts/ChronicleDisplayItalic.otf');
    font-weight: normal;
    font-style: italic;
}

